import React, {useMemo} from "react"
import { Layout } from "../../layouts"
import TopComponent from "../../components/TopComponent/TopComponent"
import Contact from "../../components/Contact/Contact"
import Testimonial from "../../components/Testimonial/Testimonial"
import "./Testimonials.scss"
import gifImage from "../../assets/images/gif-images/gif-big.svg"
import { graphql } from "gatsby"
import { MESSAGE_TYPES } from "../../constants"
import SEO from "../../components/Seo"
import { localizeStaticNodes } from "../../utils/localizeDataNodes"
// import { shuffleArray } from "../../utils/shuffleArray"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
//import { testimonialsPageTestimonialData } from "../../data/testimonial-component-data"

//const MAX_NUMBER_OF_TESTIMONIALS = 9

const Testimonials = ({ pageContext: { pageData, lang, testimonials, companies }, data, location }) => {
  // const { allDzemoApiTestimonials } = useMemo(
  //   () => localizeDataNodes(data, lang),
  //   [data, lang]
  // )

  // const [shaffledArray, setShuffledArray] = useState(
  //   testimonialsPageTestimonialData
  // )

  // useEffect(() => {
  //   setShuffledArray(
  //     shuffleArray(allDzemoApiTestimonials, MAX_NUMBER_OF_TESTIMONIALS)
  //   )
  // }, [allDzemoApiTestimonials])

  const localizedTops = useMemo(() => localizeStaticNodes(pageData[lang].tops, lang), [pageData, lang])

  return (
    <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <div className="testimonials-page">
        <section
          style={{
            display: "flex",
            minHeight: "250px",
          }}
        >
          {localizedTops.length > 0 && (
            <TopComponent data={localizedTops} imageFormatName={"normal"} />
          )}
        </section>

        <div className="gif-content">
          <img src={gifImage} alt="gif" width="414" height="81" />
        </div>

        <section className="testimonials-wrapper">
          <div className="testimonials-container">
            {testimonials.map(testimonial => {
              const { id } = testimonial.node[lang]
              return (
                <Testimonial
                  key={id}
                  data={{ ...testimonial.node[lang], imageOrientation: "left" }}
                />
              )
            })}
          </div>
        </section>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
          />
        </section>
      </div>
    </Layout>
  )
}

export default Testimonials

export const query = graphql`
  query testimonialsPageQuery($pageId: Int!) {
    allDzemoApiTops(filter: { en: { page: { id: { eq: $pageId } } } }) {
      nodes {
        id
        en {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
        de {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
      }
    }

    allDzemoApiTestimonials(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
          position {
            name
            name_de
          }
          company {
            name
            name_de
          }
          city {
            name
            name_de
          }
          country {
            name
            name_de
          }
        }
        de {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
          position {
            name
            name_de
          }
          company {
            name
            name_de
          }
          city {
            name
            name_de
          }
          country {
            name
            name_de
          }
        }
      }
    }
  }
`
